/*
  global google
  global window
  global fetch
*/
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';

import { GoogleKey, Center, apiHeaders, apiServer, linkPrefix } from '../config';
import * as actions from '../Actions';
import i18n from '../i18n.json';
import LocationSurvey from './LocationSurvey';
import PanelQuizDone from './PanelQuizDone';


const googleMapURL = `https://maps.googleapis.com/maps/api/js?libraries=visualization&key=${GoogleKey}`;
const iamgeMarkHome = `${linkPrefix}/img/mark-home.png`;

const data2marker = function data2market(el, type) {
  const marker = {
    type,
    lat: parseFloat(el.area_lat),
    lng: parseFloat(el.area_lng),
  };
  Object.assign(marker, el);
  return marker;
};

const renderMarker = function renderMarker(marker, onClick) {
  const info = (marker.text) ? <InfoWindow><div>{marker.text}</div></InfoWindow> : undefined;
  const lat = marker.lat;
  const lng = marker.lng;
  return (<Marker
    key={marker.area_name_en + marker.type + marker.lat}
    position={new google.maps.LatLng(lat, lng)}
    onClick={() => onClick(marker)}
    icon={marker.icon}
  >
    {info}
  </Marker>);
};

const createMapOptions = function createMapOptions() {
  return {
    disableDefaultUI: true,
  };
};

const MapComponent = withScriptjs(
  withGoogleMap(
    (props) => {
      const heatmap = props.heatmap ? (<HeatmapLayer
        data={props.heatmap.map(m => ({
          weight: m.weight,
          location: new google.maps.LatLng(m.location.lat, m.location.lng),
        }))}
        options={{
          radius: 64,
        }}
      />) : '';
      return (
        <GoogleMap
          ref={props.onMapLoad}
          defaultZoom={9}
          defaultCenter={Center}
          center={props.center}
      // Pass the map reference here as props
          googleMapURL={googleMapURL}
          options={createMapOptions()}
          onClick={d => props.onMapClick(d)}
        >

          {props.markers.map(marker => renderMarker(marker, props.onMarkerClick))}
          {heatmap}
        </GoogleMap>
      );
    },
    ));


class MyHeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      user: false,
      center: Center,
      compact: { quiz: true, city: false },
      pictureGame: [],
      freeSpeech: [],
      tab: 'free-speech',
    };
  }

  componentDidMount() {
    this.quizResults(this.props.winners, this.props.heatmap);
    // this.fetchMarkers();
  }

  onMapLoad(map) {
    this.map = map;
  }

  onMapClick(param) {
    const markers = this.state.markers;
    if (markers.length > 0 && markers.length <= 4) {
      const gk = new google.maps.Geocoder();
      gk.geocode({ location: param.latLng }, (res) => {
        const location = this.state.location;
        try {
          location.name = res[0].address_components[2].long_name;
        } catch (e) {
          location.name = '?';
        }
        this.setState({ location });
      });
      const location = {
        type: 2,
        lat: param.latLng.lat(),
        lng: param.latLng.lng(),
        icon: iamgeMarkHome,
      };
      if (markers.length < 4) { markers.push(location); } else { markers[3] = location; }
      this.setState({ location, markers });
    }
  }

  onMarkerClick(marker) {
    if (marker.area_hash) {
      this.setState({
        center: marker,
        area: marker,
        compact: { quiz: false, city: true },
        pictureGame: [],
        freeSpeech: [],
      });
      this.fetchPictureGame(marker);
      this.fetchFreeSpeech(marker);
    }
  }

  quizResults(results, heatmap) {
    const markers = [];
    this.storedMarkers = this.state.markers;
    results.forEach((cnt, i) => {
      markers.push({
        lat: Number(cnt.lat),
        lng: Number(cnt.lng),
        area_name_en: i,
        icon: `${linkPrefix}/img/mark-${i + 1}.png`,
        type: i,
        //text: (i === 0) ? `${i18n['map-quiz-target1']} ${this.props.winners[0].name}.` : false
      });
    });
    this.setState({ center: results[0], markers, heatmap, location: results[0] });
  }

  i18n(key) {
    return i18n[key] || `Not found in i18n file: ${key}`;
  }

  // FIXME: it actually await one connect and launches other.
  fetchMarkers() {
    const params = { headers: apiHeaders };
    this.setState({ markers: [] });
    const url0 = `${apiServer}/api/v1/areas?type=0`;
    fetch(url0, params)
    .then(response => response.json())
    .then((newMarkers) => {
      const markers = this.state.markers;
      newMarkers.forEach(el => markers.push(data2marker(el, 0)));
      this.setState({ markers });
    });
    const url1 = `${apiServer}/api/v1/areas?type=1`;
    fetch(url1, params)
    .then(response => response.json())
    .then((newMarkers) => {
      const markers = this.state.markers;
      newMarkers.forEach(el => markers.push(data2marker(el, 1)));
      this.setState({ markers });
    });
  }

  expandQuiz() {
    const markers = this.storedMarkers || this.state.markers;
    this.setState({ compact: { quiz: true, city: false }, markers, heatmap: [] });
  }

  renderArea() {
      // Pretty height
    let height = window.innerHeight;
    if (height >= 768) {
      height -= 520;
    } else {
      height -= 260;
    }
    const style = { maxHeight: `${height}px` };

    if (!this.state.area || !this.state.compact.city) {
      return undefined;
    }
    const area = this.state.area;
    const country = area[`country_name_${this.state.lang}`] || area.country_name_en;
    const place = area[`area_name_${this.state.lang}`] || area.area_name_en;
    const error = (area.cors) ? (<span className="alert alert-warning">
      <i className="icon-spinner icon-spin icon-large" />
      Seems like CORS problem</span>) : undefined;
    return (<div className="data-area panel panel-danger">
      <div className="panel-heading">
        <h3 className="panel-title">
          {`${place} (${country})`}
          <i
            role="button"
            tabIndex={0}
            className="glyphicon glyphicon-remove panel-close"
            onClick={() => this.setState({ compact: { quiz: true, city: false } })}
          />
        </h3>
      </div>
      <div
        className="panel-body"
        style={style}
      >
        <ul className="panel-list">
          {error}
        </ul>
      </div>
    </div>
    );
  }

  render() {
    const area = this.renderArea();
    const panel = (!this.props.finished) ? (<LocationSurvey
      onSubmit={data => this.props.actions.submitFeedback(data, this.state.location)}
      location={this.state.location}
      winners={this.props.winners}
    />) : (<PanelQuizDone sessionId={this.props.sessionId} />);
    return (
      <div>
        <div className="popup-wr">
          <h1 className="project-title">{i18n.title}</h1>
          <div className="col-xs-4 menu">
            {area}
            {panel}
          </div>

          <MapComponent
            markers={this.state.markers}
            center={this.state.center}
            heatmap={(!this.state.compact.city) ? this.state.heatmap : false}

            loadingElement={<div style={{ height: '10%' }} />}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB0iWMAUX4z09_rAv6ECElUtU6JDCgH0Ew&libraries=visualization"

            onMapLoad={m => this.onMapLoad(m)}
            onMarkerClick={marker => this.onMarkerClick(marker)}
            containerElement={<div className="mapContainer" />}
            onMapClick={d => this.onMapClick(d)}
            mapElement={<div className="mapElement" />}
          />
        </div>
      </div>);
  }
}

MyHeatMap.propTypes = {
  winners: PropTypes.array.isRequired,
  heatmap: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  finished: PropTypes.bool.isRequired,
  sessionId: PropTypes.string.isRequired,
};
const ConnectedHeatMap = connect(
  state => ({
    winners: state.main.winners,
    heatmap: state.main.heatmap,
    finished: state.main.finished,
    sessionId: state.main.sessionId }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(MyHeatMap);

export default ConnectedHeatMap;
