/**
global window
**/
import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n.json';
import { LinkButton } from './Helpers';

export default function PanelQuizDone(props) {
  return (
    <div className="panel panel-info panel-quiz">
      <div className="panel-heading">
        <h3 className="panel-title">{i18n.thanx}</h3>
      </div>
      <div className="pnel-body">
        {i18n.disclaimer}<br />
        <b>{props.sessionId}</b>
        <div>
          <button
            className="btn btn-success btn-lg start-quiz3"
            onClick={() => window.print()}
          >{i18n.printButton}</button>
          <LinkButton className="locationSurvey-linkButton" />
        </div>
      </div>
    </div>);
}

PanelQuizDone.propTypes = { sessionId: PropTypes.string.isRequired };