import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n.json';

const ProgressBar = function ProgressBar(props) {
  const position = props.position;
  const length = props.length;
  const progress = (position / length) * 100;
  const style = { width: `${progress}%` };
  return (<div className="pull-right">
    <div className="progress">
      <div
        className="progress-bar progress-bar-success"
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
        style={style}
      >{`${position} ${i18n.progressOf} ${length}`}</div>
    </div>
  </div>);
};

ProgressBar.propTypes = {
  length: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
};

export default ProgressBar;
