import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../Actions';
import * as pageTypes from '../pageTypes';

import Survey from './Survey';
import Quiz from './Quiz';
import SoundRecord from './SoundRecord';
import HeatMap from './HeatMap';

function QuizWithRecord(props) {
  switch (props.page) {
    case pageTypes.PAGE_QUESTION:
      return <Quiz />;
    case pageTypes.PAGE_SURVEY:
      return <Survey lang="nl" />;
    case pageTypes.PAGE_RECORD:
      return <SoundRecord />;
    case pageTypes.PAGE_MAP:
      return <HeatMap />;
    default:
      return `Error! Unknown page type ${props.page}`;
  }
  // return (
  //       <Router>
  //         <div className="App">
  //           <Header />
  //           <Nav />
  //           <Route path="/about" component={() => <Intro startQuiz={props.actions.startQuiz} page={props.page}}
  //           <Route path="/disclaimer" component={() => <Intro startQuiz={props.actions.startQuiz} page={props.page}}
  //           <Route path="/intro" component={() => <Intro startQuiz={props.actions.startQuiz} page={props.page}}
  //           <Route path="/imprint" component={() => <Intro startQuiz={props.actions.startQuiz} page={props.page}}
  //           <Route path="/quiz" component={Quiz} />
  //           <Route path="/survet" component={Survey} />
  //           <Route path="/record" component={SoundRecord} />
  //           <Route path="/heatmap" component={HeatMap} />
  //           <Footer />
  //         </div>
  //       </Router>);
}

QuizWithRecord.defaultProps = { page: pageTypes.PAGE_QUESTION, position: 0 };

QuizWithRecord.propTypes = { page: PropTypes.string, position: PropTypes.number };

const ConnectedQuizWithRecord = connect(
  state => ({ page: state.main.page }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(QuizWithRecord);

export default ConnectedQuizWithRecord;
