import CitiesData from '../cities.json';

Object.keys(CitiesData).forEach(cityId => {
    CitiesData[cityId].lat = Number(CitiesData[cityId].lat)
    CitiesData[cityId].lng = Number(CitiesData[cityId].lng)
})

export function getCity(id) {
    if (CitiesData[id]) {
        return CitiesData[id];
    } else {
        console.warn(`Wrong city ID: '${id}'.`);
    }
}

export default class Cities extends Array {
  constructor(data) {
    super();
    if (data && data.length) {
      data.forEach(e => this.push(e));
    }
  }

  get(i) {
    try {
        return CitiesData[this[i][0]];
    } catch (e) {
        console.warn("City not found", i);
    }
  }

  getName(i) {
    const obj = this.get(i);
    return (obj) ? obj.name : '';
  }


}

