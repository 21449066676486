import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from '../i18n.json';
import * as actions from '../Actions';
import survey from '../survey.json';

class Survey extends Component {

  constructor(props) {
    super(props);
    this.state = {
      0: survey[0].answers[1].id,
      1: survey[1].answers[2].id,
    };
  }

  renderQuestion(q, i) {
    const name = `select_${i}`;
    return (<div className="formElement" key={i}>
      <label htmlFor={name}>{q[`text_${this.props.lang}`]}</label>
      <select
        name={name}
        className="language form-control"
        onChange={(evt) => {
          const state = this.state;
          state[i] = evt.target.value;
          this.setState(state);
        }}
        value={this.state[i]}
      >
        {q.answers.map(a => <option key={a.id} value={a.id}>{a[`text_${this.props.lang}`]}</option>)}
      </select>
    </div>);
  }

  render() {
    const disabled = false; // Check if data is filled, if force survey.
    return (
      <div>
        <h1 className="project-title">{i18n.title}</h1>
        <p className="project-subtitle">{i18n.surveySubtitle}</p>
        <div className="panel panel-info">

          <div className="quiz panel panel-info panel-body-long">
            <div className="panel-heading">
              <h4 className="panel-title">{i18n.surveyTitle}</h4>
            </div>
            <div className="survey-data">
              {survey.map((q, i) => this.renderQuestion(q, i))}
              <div className="text-center">
                <button
                  className={`btn btn-danger btn-lg start-quiz ${disabled ? 'disabled' : ''}`}
                  onClick={() => !disabled && this.props.actions.submitSurvey(this.state)}
                >{i18n.continueQuiz}</button>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

Survey.defaultProps = { lang: 'nl' };
Survey.propTypes = {
  actions: PropTypes.object.isRequired,
  lang: PropTypes.string,
};

const ConnectedSurvey = connect(
  state => ({ results: state.results }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(Survey);

export default ConnectedSurvey;
