import React from 'react';
import i18n from '../i18n';
import { linkButtonLink } from '../config'

export function LinkButton(props) {
  const buttonText = i18n.linkButtonText;
  const buttonLink = linkButtonLink;

 return (<div className={props.className}>
    <a
      className="btn btn-primary btn-lg start-quiz"
      rel="noopener noreferrer"
      href={buttonLink}
      target="_blank"
    >{buttonText}</a>
  </div>
  );
}