import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from '../i18n.json';
import * as actions from '../Actions';
import { linkPrefix } from '../config';

function MenuElement(props) {
  return (<li className="nav-item menu__item">
    <Link
      className={`nav-link  btn menu__btn ${(props.active) ? 'active' : ''}`}
      to={props.link || '#'}
    >{props.title}</Link>
  </li>);
}

MenuElement.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

function Menu(props) {
  const { pathname } = props.location;
  if (props.embedded) return '';
  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-light mx-auto order-0">
      <div className="mx-auto order-0" id="navbarSupportedContent">
        <ul className="navbar-nav mx-auto">
          <MenuElement
            active={pathname.search('/quiz') >= 0}
            title={i18n.menuStart}
            link={`${linkPrefix}/quiz`}
          />
          <MenuElement
            active={pathname === `${linkPrefix}/`}
            title={i18n.menuAbout}
            link={`${linkPrefix}/`}
          />
          <MenuElement
            active={pathname.search('/imprint') >= 0}
            title={i18n.menuImprint}
            link={`${linkPrefix}/imprint`}
          />
          <MenuElement
            active={pathname.search('/disclaimer') >= 0}
            title={i18n.menuDisclaimer}
            link={`${linkPrefix}/disclaimer`}
          />
        </ul>
      </div>
    </nav>);
}
Menu.defaultProps = { active: false, embedded: false };
Menu.propTypes = {
  embedded: PropTypes.bool,
};

const ConnectedMenu = withRouter(connect(
  state => ({ page: state.page, embedded: state.main.embedded }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(Menu));

export default ConnectedMenu;
