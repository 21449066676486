/*
global window
*/
import * as types from './actionTypes';
import * as pageTypes from './pageTypes';
import { uniqueId } from './functions';
import Results from './models/results';
import { shuffle } from './functions';

const quiz = shuffle(Object.values(require('./quiz.json')));

// const heatmapMock = [
//  { location: {
//     name: 'Rouveen',
//     province: 'Overijssel',
//     lng: 6.1929,
//     lat: 52.6172 }, weight: 26 },
//  { location: {
//     name: 'Bergentheim',
//     province: 'Overijssel', lng: 6.61659, lat: 52.5335 }, weight: 26 },
//  { location: {
//     name: 'Deventer',
//     province: 'Overijssel', lng: 6.17015, lat: 52.259 }, weight: 17 },
//  { location: {
//     name: 'Eenrum',
//     province: 'Groningen', lng: 6.4544, lat: 53.3688 }, weight: 13 },
//  { location: {
//     name: 'Gasselte',
//     province: 'Drenthe', lng: 6.79061, lat: 52.9743 }, weight: 12 },
// ];

// const winnersMock = [
//    { name: 'Bathmen', province: 'Overijssel', lng: 6.28896, lat: 52.2572 },
//    { name: 'Deventer', province: 'Overijssel', lng: 6.17015, lat: 52.259 },
//    { name: 'Heino', province: 'Overijssel', lng: 6.24519, lat: 52.4367 },
// ];

// const genderMock = { gender: 1, aaa: 2}


// const initialState = {
//   position: 0,
//   sessionId: uniqueId(),
//   quizId: 666,
//   surveyData: genderMock,
//   page: pageTypes.PAGE_MAP,
//   results: new Results(quiz.length),
//   winners: winnersMock,
//   heatmap: heatmapMock,
//   finished: false,
//   quiz,
// };

const embedded = window.location.search.indexOf('embed') !== -1;

const initialState = {
  position: 0,
  sessionId: uniqueId(),
  quizId: 666,
  surveyData: undefined,
  page: pageTypes.PAGE_QUESTION,
  quiz,
  results: new Results(quiz.length),
  winners: [],
  heatmap: [],
  finished: false,
  blockRecording: window.location.search.indexOf('blockRecording') !== -1,
  embedded,
};

function setVote(state, data) {
  const results = state.results;
  results.set(data, state.position);
  if (state.blockRecording) {
    return {
      ...state,
      position: state.position + 1,
      page: pageTypes.PAGE_QUESTION,
    };
  }
  return { ...state, results, page: pageTypes.PAGE_RECORD };
}

function setRecorded(state, data) {
  const results = state.results;
  results.setRecorded(data.pos);
  return { ...state, surveyId: data.surveyId };
}

export default function reducer(state = initialState, action) {
 // console.log('Reducer', action, state);
  const data = action.data;
  switch (action.type) {
    case types.ERROR:
      console.error(`Handled error: ${data}`);
      return { ...state, error: data };
    case types.NEXT_QUESTION:
      return { ...state, position: state.position + 1, page: pageTypes.PAGE_QUESTION };
    case types.SHOW_SURVEY:
      return { ...state, page: pageTypes.PAGE_SURVEY };
    case types.VOTE:
      return setVote(state, data);
    case types.SET_SURVEY_DATA:
      return { ...state, surveyData: data, page: pageTypes.PAGE_MAP };
    case types.SET_QUIZ_ID:
      return { ...state, quizId: data };
    case types.SET_QUIZ_DATA:
      return { ...state, ...data };
    case types.SET_POSITION:
      return { ...state, position: data, page: pageTypes.PAGE_QUESTION };
    case types.SENT_AUDIO:
      return setRecorded(state, data);
    case types.SET_FINISHED:
      return { ...state, finished: true };
    case types.START:
      return { ...initialState, page: pageTypes.PAGE_QUESTION };
    case types.GO_PAGE:
      return { ...state, page: data };
    case types.START_QUIZ:
      return { ...initialState, page: pageTypes.PAGE_QUESTION };
    case types.RETURN_TO_QUESTION:
      return { ...state, page: pageTypes.PAGE_QUESTION };
    case types.SET_BLOCK_RECORDING:
      return { ...state, blockRecording: true };
    default:
      return state;
  }
}
