import { apiSubmitSurvey, apiHeadersJSON } from './config';

export function uniqueId() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4() + s4()}-${s4() + s4()}`;
}

export function shuffle(src) {
  let currentIndex = src.length;
  const array = src.slice();
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    const temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export async function sendFeedback(survey, location, lang, comment, session_id) {
  try {
      const data = {
        answers: survey,
        device_id: uniqueId(),
        session_id: session_id,
        location: { area_lat: location.lat, area_lng: location.lng, user_lat: 1, user_lng: 1 },
        user_lng: lang,
        area_lng: lang,
        platform: 2,
        comment,
      };
      const response = await fetch(apiSubmitSurvey, {
        method: 'POST',
        headers: new Headers(apiHeadersJSON),
        body: JSON.stringify(data),
      })
      return await response.json()
    } catch (e) {
        throw e
    }
};
