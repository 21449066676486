module.exports.androidAppUrl = 'https://play.google.com/store/apps/details?id=nl.rug.stimmen';
module.exports.iosAppUrl = 'https://itunes.apple.com/us/app/stimmen/id1271302356?mt=8&at=1l3v7uC';

const apiServer = 'https://api.woordwaark.nl';
module.exports.apiServer = apiServer;

module.exports.apiRequestRecord = `${apiServer}/api/v1/prediction-quiz/recordings/`;
module.exports.apiPredictionQuiz = `${apiServer}/api/v1/prediction-quiz/`;
module.exports.apiSubmitSurvey = `${apiServer}/api/v1/submit_survey/`;

module.exports.doNotSendAudio = false; // debug only. Prevents sound sample uploading
module.exports.MaxRecordTime = 5000; // Delay to autostop sample recording
module.exports.Center = { lat: 53.219383, lng: 6.566502, name: 'Groningen' };
module.exports.GoogleKey = 'AIzaSyB0iWMAUX4z09_rAv6ECElUtU6JDCgH0Ew';
module.exports.linkPrefix = '/stemmen';
module.exports.recordIsMandatory = true;
module.exports.linkButtonLink = 'https://goo.gl/forms/zsoKwT0PaaBxCN1k2';
module.exports.apiHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
};
module.exports.apiHeadersJSON = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
};
