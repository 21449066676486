export const PAGE_QUESTION = 'pageQuestion';
export const PAGE_RECORD = 'pageRecord';
export const PAGE_SURVEY = 'pageSurvey';
export const PAGE_MAP = 'pageMap';

export const PAGE_INTRO = 'intro';
export const PAGE_IMPRINT = 'imprint';
export const PAGE_ABOUT = 'about';
export const PAGE_DISCLAIMER = 'disclaimer';
export const PAGE_DEFAULT = PAGE_INTRO;
