/*
 global URL
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sound from 'react-sound';

import Recorder from './Recorder';
import * as actions from '../Actions';

import Results from '../models/results';
import ProgressBar from './ProgressBar';
import i18n from '../i18n.json';
import { MaxRecordTime, recordIsMandatory } from '../config';
import Menu from './Menu';

function PlayerButton(props) {
  if (props.hidden) return '';
  return (
    <div className="playerButton">
      <button
        type="button"
        className={`btn btn-xl btn-circle playerButton ${props.classes}`}
        onClick={props.action}
      ><i className={`fa ${props.icon}`} />
      </button><br />
      <label htmlFor="none" className="button-explain">{props.explain}</label>
    </div>);
}

PlayerButton.defaultProps = { explain: '', hidden: false, classes: '' };
PlayerButton.propTypes = {
  explain: PropTypes.string,
  hidden: PropTypes.bool,
  classes: PropTypes.string,
  action: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

function I18N(key, insertions = {}) {
  let val = i18n[key];
  Object.entries(insertions).forEach(v => val = val.replace(`%${v[0]}%`, v[1]));
  return val;
}

class SoundRecord extends Component {

  constructor(props) {
    super(props);
    this.state = {
      recording: false, // Записываем или нет
      record: false,    // Данные записи
      recorded: props.results.recorded(props.position),  // Уже записано ?
      playing: Sound.status.STOPPED, // Статус проигрывания звука
    };
  }

  onRecord() {
    if (this.state.playing !== Sound.status.PLAYING) {
      this.setState({ recording: !this.state.recording });
      if (!this.state.recording) {
        this.timer = setTimeout(() => this.state.recording && this.onStop(), MaxRecordTime);
      } else {
        clearTimeout(this.timer);
      }
    }
  }

  onPlay() {
    if (this.state.record && !this.state.recording) {
      this.setState({ playing: Sound.status.PLAYING });
    }
  }

  onStop(blob) {
    this.setState({ record: blob, recording: false, recorded: true });
  }

  onNext() {
    if (this.state.recorded) {
      if (this.state.record) {
        this.props.actions.sendRecord(this.state.record, this.props.position);
      }
    }
    this.props.actions.nextQuestion();
  }

  explainButton() {
    if (this.state.recording) return i18n.explainRecordOn;
    if (this.state.recorded) return i18n.explainReRecord;
    return i18n.explainRecordStart;
  }

  audio() {
    if (this.state.playing !== Sound.status.PLAYING) return '';
    const url = URL.createObjectURL(this.state.record);
    return (<Sound
      playStatus={this.state.playing}
      onFinishedPlaying={() => this.setState({ playing: Sound.status.STOPPED })}
      url={url}
    />);
  }

  recorder() {
    if (this.state.playing !== Sound.status.PLAYING) {
      return (<Recorder onStop={b => this.onStop(b)} recording={this.state.recording} />);
    }
  }

  get nextButton() {
    if (recordIsMandatory && !this.state.record) return undefined;
    return (
      <button
        type="button"
        className={'btn btn-primary soundRecord--nextButton'}
        onClick={() => this.onNext()}
      >{i18n.next}</button>)
  }

  render() {
    const block = this.props.quiz[this.props.position];
    const replacements = {
      word: block.name,
    };
    return (<div>
      <Menu />
      <h1 className="project-title">{i18n.title}</h1>
      <p className="project-subtitle" dangerouslySetInnerHTML={{ __html: I18N('recordSubtitle', replacements) }} />
      {this.audio()}
      <div className={'quiz panel panel-info panel-body-long'}>
        <div className="panel-heading">
          <h4 className="panel-title">{I18N('recordTitle', replacements)}</h4>
        </div>

        <div className="">
          <div className="text-center">
            {this.recorder()}
            <div className="playerWrapper">
              <PlayerButton
                explain={this.explainButton()}
                action={() => this.onRecord()}
                classes={`${this.state.recording ? 'btn-danger' : 'btn-primary'} ${this.state.playing === Sound.status.PLAYING ? 'disabled' : ''}`}
                icon="fa-microphone"
              />
              <PlayerButton
                hidden={!this.state.record}
                explain={i18n.play}
                action={() => this.onPlay()}
                classes={`${this.state.playing === Sound.status.PLAYING ? 'btn-success' : 'btn-primary'} record-button ${this.state.recording ? 'disabled' : ''}`}
                icon="fa-play"
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-info prev pull-left"
              onClick={() => this.props.actions.returnToQuestion()}
            >{i18n['quiz-prev']}</button>
            {this.nextButton}
          </div>
          <div className="panel-bottom clearfix">
            <ProgressBar
              position={this.props.position + 1}
              length={this.props.quiz.length}
            />
          </div>
        </div>
      </div>
    </div>);
  }
}

SoundRecord.propTypes = {
  position: PropTypes.number.isRequired,
  results: PropTypes.instanceOf(Results).isRequired,
  actions: PropTypes.object.isRequired,
  quiz: PropTypes.array.isRequired,
};

const ConnectedSoundRecord = connect(
  state => ({ position: state.main.position, results: state.main.results, quiz: state.main.quiz }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(SoundRecord);

export default ConnectedSoundRecord;
