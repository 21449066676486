/*
  global fetch
  global FormData
  global Headers
  global window
*/
import * as types from './actionTypes';
import { apiRequestRecord, apiPredictionQuiz, doNotSendAudio, apiHeadersJSON } from './config';
import { getCity } from './models/cities';
import { sendFeedback } from './functions';

export function vote(id) {
  if (!id) throw new Error('No vote id!');
  return { type: types.VOTE, data: id };
}

export function returnToQuestion() {
  return { type: types.RETURN_TO_QUESTION };
}

export function setBlockRecording() {
  return { type: types.SET_BLOCK_RECORDING };
}

export function submitQuiz(cities, answers) {
  return async (dispatch, getState) => {
    const state = getState().main;
    const data = {
      answers,
      app_locale: 'en',
      area1_name: cities.getName(0),
      area2_name: cities.getName(1),
      area3_name: cities.getName(2),
      session_id: state.sessionId,
    };
    try {
      const port = window.location.port ? `${window.location.port}` : '';
      const host = `${window.location.hostname}${port}`;
      const response = await fetch(apiPredictionQuiz, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Origin: host,
        }),
        body: JSON.stringify(data),
      });
      const result = await response.json();
      dispatch({ type: types.SET_QUIZ_ID, data: result.quiz_id });
    } catch (e) {
      dispatch({ type: types.ERROR, data: e.message });
    }
  };
}

export function nextQuestion() {
  return (dispatch, getState) => {
    const state = getState().main;
    if (state.position + 1 === state.results.length) {
      dispatch({ type: types.SHOW_SURVEY });
      const cities = state.results.calculateCities(state.quiz);
      const answers = state.results.prepareAnswers(state.quiz);
      dispatch(submitQuiz(cities, answers));
      const winners = [cities.get(0), cities.get(1), cities.get(2)];
      const heatmap = cities.map((el) => {
        if (el[1]) {
          return {
            location: getCity(el[0]),
            weight: el[1] * 13,
          };
        }
        return undefined;
      });
      dispatch({ type: types.SET_QUIZ_DATA, data: { heatmap, winners } });
    } else {
      dispatch({ type: types.NEXT_QUESTION, data: state.position });
    }
  };
}

export function sendRecord(blob, position) {
  return async function ssR(dispatch, getState) {
    if (doNotSendAudio) {
      dispatch({ type: types.SENT_AUDIO, data: position });
      return;
    }
    try {
      const state = getState().main;
      const url = apiRequestRecord;
      const formData = new FormData();
      const question = state.quiz[state.position];
      const answerId = state.results.get(state.position);
      formData.append('session_id', state.sessionId);
      formData.append('question_text', question.name);
      formData.append('answer_text', question.prons[answerId].t);
      formData.append('recording', blob);
      const result = await fetch(url,
        {
          method: 'POST',
          body: formData,
        });
      if (result.status >= 400) throw new Error(`Wrong response status ${result.status}`);
      const response = await result.json();
      dispatch({ type: types.SENT_AUDIO, data: { pos: position, surveyId: response.id } });
    } catch (e) {
      dispatch({ type: types.ERROR, data: e });
    }
  };
}

export function submitFeedback(feedback, location) {
  return async (dispatch, getState) => {
    const state = getState().main;
    try {
      if (!state.quizId) {
        throw new Error('No quizId is set!');
      }
      if (!state.surveyData) {
        throw new Error('No surveyData is set!');
      }
      const surveyResponse = await sendFeedback(Object.values(state.surveyData), location, 'nl', '',  state.sessionId);
      const data = {
        rating: 0,
        survey_result: surveyResponse.survey_id,
        session_id: state.sessionId,
      };
      const response = await fetch(`${apiPredictionQuiz}${state.quizId}/`, {
        method: 'PATCH',
        headers: new Headers(apiHeadersJSON),
        body: JSON.stringify(data),
      });
      await response.json();
      dispatch({ type: types.SET_FINISHED });
    } catch (e) {
      dispatch({ type: types.ERROR, data: e.message });
      dispatch({ type: types.SET_FINISHED });
    }
  };
}

// export function submitSurvey(surveyData) {
//   // return (dispatch, getState) => {
//     // dispatch({ type: types.SET_SURVEY_DATA, data: surveyData });
//   // };
//   return { type: types.SET_SURVEY_DATA, data: [16,14] };
// }

export function submitSurvey(surveyData) {
  return { type: types.SET_SURVEY_DATA, data: surveyData };
}

export function back() {
  return (dispatch, getState) => dispatch({
    type: types.SET_POSITION,
    data: Math.max(0, getState().main.position - 1) });
}

export function goLast() {
  return (dispatch, getState) => dispatch({
    type: types.SET_POSITION,
    data: getState().main.results.nextPos(),
  });
}

export function restart() {
  return { type: types.START };
}

export function goStart() {
  return { type: types.START };
}

export function goPage(pageType) {
  return { type: types.GO_PAGE, data: pageType };
}

export function startQuiz() {
  return { type: types.START_QUIZ };
}

