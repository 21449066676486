import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sound from 'react-sound';
// import Cities from '../cities';
// import Survey from './Survey';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Results from '../models/results';
import I18n from '../i18n.json';
import * as actions from '../Actions';
import { linkPrefix } from '../config';
import ProgressBar from './ProgressBar';
import Menu from './Menu';

// import PostSurvey from './PostSurvey';

const emptyState = {
  playing: 0,
  audio: 0,
};


function Variant(props) {
  const checked = props.checked ? 'checked' : '';
  return (
    <li className="list-item" key={props.id}>
      <div className={`hover clearfix ${checked}`}>
        <label
          htmlFor="nothing"
          role="button"
          tabIndex={-1}
          style={{float: "right"}}
          id={props.id}
          onClick={() => props.onPlay()}
        >
        {props.text}
        <i
          className="fa fa-check-circle"
          style={{float: "right", lineHeight: "30px", color: "darkgreen", fontSize: "18px"}}
          onClick={() => props.onSelect()}
        >
          &nbsp;Kies
        </i>
        </label>
        <i
          role="button"
          style={props.style}
          tabIndex={0}
          className="fa fa-play-circle quiz-play "
          onClick={() => props.onPlay()}
        />
      </div>
    </li>);
}

Variant.defaultProps = { checked: false, style: undefined };

Variant.propTypes = {
  style: PropTypes.string,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

function i18n(a) {
  if (!I18n[a]) console.warn(`No i18 4 "${a}"!`);
  return I18n[a];
}


class Quiz extends Component {

  constructor(props) {
    super(props);
    this.state = emptyState;
  }

  render() {
    const pos = this.props.position || 0;

    const block = this.props.quiz[pos];
    const variants = Object.entries(block.prons).map(v => (<Variant
      key={v[0]}
      id={v[0]}
      text={v[1].t}
      style={(v[0] === this.state.audio && this.state.playing === Sound.status.PLAYING) ? ({ color: '#28a745' }) : undefined}
      checked={this.props.results.is(this.props.position, v[0])}
      onSelect={() => this.props.actions.vote(v[0])}
      onPlay={() => this.setState({ audio: v[0], playing: true })}
    />));
    const nextControl = (pos < this.props.results.actualPos()) ? (<button
      className="btn btn-info pull-left"
      onClick={() => this.props.actions.goLast()}
    >&gt;&gt;</button>) : undefined;

    const controls = (pos) ? (<div>
      <button
        className="btn btn-info prev pull-left"
        onClick={() => this.props.actions.back()}
      >{i18n('quiz-prev')}</button>
      {nextControl}
    </div>) : '';

    const audio = (this.state.audio) ? (<Sound
      playStatus={(this.state.playing) ? Sound.status.PLAYING : Sound.status.PAUSED}
      onFinishedPlaying={() => this.setState({ playing: false })}
      url={`${linkPrefix}/audio/${this.state.audio}.mp3`}
    />) : undefined;
    return (
      <div>
        <Menu />
        <h1 className="project-title">{I18n.title}</h1>
        <p className="project-subtitle">{I18n.subtitle}</p>
        <div className="panel panel-info">
          {audio}

          <div className="quiz panel panel-info panel-body-long">
            <div className="panel-heading">
              <h4 className="panel-title">{i18n('question-prefix')} <span className="quiz-word">&laquo;{block.name}&raquo;</span>?</h4>
            </div>
            <div>
              <ul className="panel-list">
                {variants}
              </ul>
              <div className="panel-bottom clearfix">
                {controls}
                <ProgressBar
                  position={this.props.position + 1}
                  length={this.props.quiz.length}
                />
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

Quiz.defaultProps = {
  audio: '',
};

Quiz.propTypes = {
  position: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  results: PropTypes.instanceOf(Results).isRequired,
  quiz: PropTypes.array.isRequired,
  audio: PropTypes.string,
};

const ConnectedQuiz = connect(
  state => ({ position: state.main.position, results: state.main.results, quiz: state.main.quiz }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(Quiz);

export default ConnectedQuiz;
