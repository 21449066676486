import Cities from './cities';

export default class Results {

  constructor(len) {
    this.data = new Array(len);
    this.maxRecorded = -1;
    this.length = len;
  }

  actualPos() {
    return this.data.filter(a => a !== undefined).length;
  }

  nextPos() {
    return this.actualPos() + 1;
  }

  is(pos, value) {
    return this.data[pos] === value;
  }

  set(value, pos) {
    this.data[pos] = value;
  }

  get(pos) {
    return this.data[pos];
  }

  setRecorded(pos) {
    this.maxRecorded = Math.max(this.maxRecorded, pos);
  }

  recorded(pos) {
    return pos <= this.maxRecorded;
  }

  calculateCities(quiz) {
    const cities = [];
    this.data.forEach((res, i) => {
      if (!res) return;
      const prons = quiz[i].prons;
      if (prons[res] && prons[res].w) {
        prons[res].w.forEach((city) => {
          if (cities[city]) {
            cities[city] += 1;
          } else {
            cities[city] = 1;
          }
        });
      }
    });
    return new Cities(Object.entries(cities).sort((a, b) => b[1] - a[1]));
  }

  prepareAnswers(quiz) {
    const answers = [];
    this.data.forEach((res, i) => {
      if (!res) return;
      const qw = quiz[i];
      answers.push({
        question_text: qw.name,
        answer_text: qw.prons[res] ? qw.prons[res].t : res,
      });
    });
    return answers;
  }

}
