import React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Helmet } from 'react-helmet';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Route, Switch } from 'react-router';
import {
  routerReducer,
  routerMiddleware,
} from 'react-router-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';

import QuizWithRecord from './components/QuizWithRecord';
import reducer from './reducer';
import i18n from './i18n.json';
import Intro from './components/Intro';
import { linkPrefix } from './config';
import { LinkButton } from './components/Helpers';

require('typeface-dosis');
require('./App.css');

const history = createHistory();
const middleware = routerMiddleware(history);

const store = createStore(
  combineReducers({ main: reducer, router: routerReducer }),
  compose(applyMiddleware(thunk), applyMiddleware(middleware)),
);

function IntroMain() { return (<Intro><LinkButton className="text-center" /></Intro>); }

export default function App() {
  return (
    <div>
      <Helmet>
        <title>{i18n.htmlTitle}</title>
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css"
          integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4"
          crossOrigin="anonymous"
        />
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          crossOrigin="anonymous"
        />

      </Helmet>
      <Provider store={store}>
        <div className="App">
          <Router>
            <div className="App">
              <Switch>
              <Route exact path={`${linkPrefix}/`} component={IntroMain} />
              <Route path={`${linkPrefix}/disclaimer`} component={Intro} />
              <Route path={`${linkPrefix}/intro`} component={Intro} />
              <Route path={`${linkPrefix}/imprint`} component={Intro} />
              <Route path={`${linkPrefix}/quiz`} component={QuizWithRecord} />
              <Route path="" component={IntroMain}/>
              </Switch>
            </div>
          </Router>
        </div>
      </Provider>
    </div>
  );
}


//
  //           <Route path="/survet" component={Survey} />
  //           <Route path="/record" component={SoundRecord} />
  //           <Route path="/heatmap" component={HeatMap} />
