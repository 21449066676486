import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n.json';

export default class PostSurvey extends Component {

  render() {
    const enabled = this.props.location.name && this.props.location.name !== this.props.winners[0].name;
    return (
      <div className="panel panel-info panel-quiz">
        <div className="quiz-winner">
          <p>{i18n.quizRestOfCites} <b>{this.props.winners[0].name} (A),</b> {this.props.winners[1].name} (B), of {this.props.winners[2].name} (C) komt.</p>
          <p>Waar plaats jij je uitspraak op de kaart? <b>Tik op deze plaats</b> en <b>verstuur</b> je antwoord!</p>
          <div className="your-city">
            <div className="your-city-ttl">{i18n['quiz-your-city-ttl']}</div>
            <input
              className="form-control"
              type="text"
              disabled
              value={this.props.location.name || i18n['quiz-location-placeholder']}
            />
          </div>
          <div>
            <button
              className={`btn btn-success btn-lg start-quiz2 ${(!enabled) ? 'disabled' : ''}`}
              onClick={() => enabled && this.props.onSubmit(this.state)}
            >{i18n['quiz-submit-post-survey']}</button>
          </div>
        </div>
      </div>);
  }
}
PostSurvey.defaultProps = { location: { name: 'MycoxpaHcK' } };
PostSurvey.propTypes = {
  location: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  winners: PropTypes.array.isRequired,
};
