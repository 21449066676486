/*
  global window
*/
import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Recorder extends Component {

  start() {
    this.mediaRecorder && this.mediaRecorder.state !== 'recording' && this.mediaRecorder.start();
  }

  stop() {
    this.mediaRecorder && this.mediaRecorder.state !== 'inactive' && this.mediaRecorder.stop();
  }

  pause() {
    this.mediaRecorder.pause();
  }

  resume() {
    this.mediaRecorder.resume();
  }

  componentDidMount() {
    const _this = this;
    if (!window.MediaRecorder) {
      window.MediaRecorder = require('audio-recorder-polyfill');
    }

    if (window.MediaRecorder) {
      (function () {
        const constraints = { audio: true };
        _this.chunks = [];
        const _props = _this.props;
        const blobOpts = _props.blobOpts;
        const onStop = _props.onStop;
        const onError = _props.onError;
        const mediaOpts = _props.mediaOpts;
        const onPause = _props.onPause;
        const onResume = _props.onResume;
        const onStart = _props.onStart;
        const gotStream = _props.gotStream;


        const onErr = function onErr(err) {
          console.warn(err);
          if (onError) onError(err);
        };

        const onSuccess = function onSuccess(stream) {
          _this.mediaRecorder = new window.MediaRecorder(stream, mediaOpts || {});

          _this.mediaRecorder.addEventListener('dataavailable', (e) => {
            _this.chunks.push(e.data);
          });

          _this.mediaRecorder.addEventListener('stop', (e) => {
            _this.chunks.push(e.data);
            const blob = new window.Blob(_this.chunks, blobOpts || { type: 'audio/wav' });
            _this.chunks = [];
            onStop(blob);
          });

          _this.mediaRecorder.addEventListener('start', () => {
            onStart && onStart();
          });

          _this.mediaRecorder.onerror = onErr;
          if (onPause) _this.mediaRecorder.onpause = onPause;
          if (onResume) _this.mediaRecorder.onresume = onResume;

          _this.stream = stream;
          if (gotStream) gotStream(stream);
        };

        navigator.mediaDevices.getUserMedia(constraints).then(onSuccess).catch(onErr);
      }());
    } else {
      console.warn('Audio recording APIs not supported by this browser');
      const onMissingAPIs = this.props.onMissingAPIs;

      if (onMissingAPIs) {
        onMissingAPIs(navigator.getUserMedia, window.MediaRecorder);
      } else {
        window.alert('Your browser doesn\'t support native microphone recording. For best results, we recommend using Google Chrome or Mozilla Firefox to use this site.');
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.command && this.props.command !== 'none' && prevProps.command !== this.props.command) {
      this[this.props.command]();
    }
  }

  componentWillUnmount() {
    if (this.props.onUnmount) this.props.onUnmount(this.stream);
  }

  render() {
    if (this.props.recording) {
      this.start();
    } else {
      this.stop();
    }
    return false;
  }


}

Recorder.propTypes = {
  command: PropTypes.oneOf(['start', 'stop', 'pause', 'resume', 'none']),
  onStop: PropTypes.func.isRequired,
  onMissingAPIs: PropTypes.func,
  onError: PropTypes.func,
  onPause: PropTypes.func,
  onStart: PropTypes.func,
  onResume: PropTypes.func,
  onUnmount: PropTypes.func,
  gotStream: PropTypes.func,
  blobOpts: PropTypes.object,
  mediaOpts: PropTypes.object,
};
